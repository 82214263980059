// All rights reserved.
// 17 U.S.C. §100-800.
// Copyright Notice File.
import './App.css';
//import logo from './Logo.png';


// React functions must return a React component
function App() {
  return (
    <div className="App">
      <header className="App-header">

        <h1 id = "displaytext" style={{ color: "red" }}> Andres Acosta </h1>
        
        <h5 id = "displaytext" style={{ color: "red" }}> Content  </h5>
        <table>
          <td>
            <a href="https://onlyfans.com/u168023732">
              <button id="button3">OnlyFans</button>
            </a>
          </td>
          <td>
            <a href="https://twitter.com/AndresAcostaXXX">
              <button id="button3">Twitter</button>
            </a>
          </td>
          <td>
            <a href="https://chaturbate.com/andresacostaxxx/">
              <button id="button3">Chaturbate</button>
            </a>
          </td>
        </table>
        <h5 id = "displaytext" style={{ color: "red" }}> Contact, Bookings, and Special Requests </h5>
        <h7 id = "displaytext" style={{ color: "red" }}> AndresAcostaXXX@proton.me </h7>
        <h5 id = "displaytext" style={{ color: "red" }}> _____________________ </h5>
        <div id = "displaytext" style={{ color: "red" }}> Adults Only </div>
        <break>
        </break>
      </header>
    </div>
  );

}
export default App
